<template>
  <transition name="fade" appear>
    <div class="app-container">
      <Sidebar
        v-if="loggedIn && $route.path !== '/promptmultifactor'"
        :menu-items="sidebarMenuItems"
      />

      <div>
        <!-- condensed sidebar for the sign in experience -->
        <DesktopSideBar v-if="!loggedIn" :menu-items="[]" />
      </div>

      <!-- <div
        class="app-content"
        :style="{
          ...(loggedIn
            ? {
                marginLeft: 0,
                width: '100%'
              }
            : {})
        }"
      > -->
      <AppContent>
        <top-nav
          v-if="loggedIn && $route.path !== '/promptmultifactor'"
          :sidebar-items="sidebarMenuItems"
        />

        <Page>
          <transition class="flex-grow-2" name="fade" mode="out-in" appear>
            <router-view />
          </transition>
        </Page>
      </AppContent>
    </div>
  </transition>
</template>
<script>
import { mapGetters } from 'vuex'
import CustomerSupportIcon from '@/components/icons/CustomerSupport'
import { windowStore } from '@/utilities/window/store'

import TopNav from './components/navs/Top/index'
import Sidebar from '@/components/Sidebar'
import Page from '@/containers/Page'
import AppContent from '@/containers/AppContent'

import DesktopSideBar from '@/components/Sidebar/components/Desktop'

export default {
  components: {
    Page,
    AppContent,
    Sidebar,
    DesktopSideBar,
    TopNav
  },

  data: function() {
    return {
      sidebarMenuItems: [
        {
          to: '/',
          icon: 'dashboard',
          label: 'Dashboard'
        },
        {
          to: '/customerSupport',
          iconC: CustomerSupportIcon,
          label: 'Customer Support'
        }
      ]
    }
  },

  computed: {
    ...mapGetters('user', ['authUser', 'loggedIn', 'hoaId'])
  },

  beforeCreate() {
    windowStore.dispatch('init')
  }
}
</script>
