import Vue from 'vue'
import VueRouter from 'vue-router'
import scrollBehavior from '../common/scrollBehavior'
import { afterEach } from '../common/afterEach'
import routes from './routes'

Vue.use(VueRouter)

const { NODE_ENV, BASE_URL } = process.env

// In dev server, each app is proxied from a sub-folder path
const base = NODE_ENV === 'development' ? '/onboarding/' : BASE_URL

const router = new VueRouter({
  mode: 'history',
  base,
  routes,

  scrollBehavior
})

router.beforeEach((to, from, next) => {
  /*
    this adds #tab so that the active tab of DetailsMenu is highlighted 
  */
  if (to.fullPath.indexOf('/userSettings/') === 0 && to.hash !== '#tab') {
    next({
      path: to.fullPath + '#tab'
    })
    return
  }

  next()
})

router.afterEach((to, from) => {
  afterEach({ to, from })
})

export default router
