import authGuard from '@/router/auth-guard'
import commonRoutes from '@/router/common/routes'
import customerSupport from './customerSupport'
import userSettings from './userSettings'

const routes = [
  // Routes shared across apps
  ...commonRoutes,
  ...userSettings,
  ...customerSupport,

  {
    path: '/alerts',
    name: 'alerts',
    component: () =>
      import(/* webpackChunkName: "notifications" */ '@/pages/Shared/notifications/Dashboard.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/alerts/:id',
    // name: 'alerts/:id',
    component: () =>
      import(/* webpackChunkName: "notifications" */ '@/pages/Shared/notifications/Dashboard.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/conversations',
    name: 'conversations',
    component: () =>
      import(
        /* webpackChunkName: "conversations" */ '@/pages/Shared/communications/Global/List.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/newconversation',
    name: 'newconversation',
    component: () =>
      import(
        /* webpackChunkName: "conversations" */ '@/pages/Shared/communications/Global/newConversation/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/conversationdetail/:id',
    name: 'conversationdetail',
    component: () =>
      import(
        /* webpackChunkName: "conversations" */ '@/pages/Shared/communications/Global/detail/index.vue'
      ),
    beforeEnter: authGuard
  },

  // Protected Routes
  // User Login Required (w/authGuard)
  {
    path: '/',
    name: 'home',
    component: () =>
      import(
        /* webpackChunkName: "onboardingDashboard" */ '@/pages/OnBoarding/dashboards/OnBoarding.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () =>
      import(
        /* webpackChunkName: "onboardingDashboard" */ '@/pages/OnBoarding/dashboards/OnBoarding.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/financialInformation',
    name: 'financialInformation',
    component: () =>
      import(
        /* webpackChunkName: "onboardingDashboard" */ '@/pages/OnBoarding/dashboards/FinancialInfo/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/boardAndCommittees',
    name: 'boardAndCommittees',
    component: () =>
      import(
        /* webpackChunkName: "onboardingDashboard" */ '@/pages/OnBoarding/dashboards/BoardAndCommittees/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '/merchantBoarding/:applink',
    name: 'merchantBoarding',
    component: () =>
      import(
        /* webpackChunkName: "onboardingDashboard" */ '@/pages/OnBoarding/dashboards/MerchantBoarding/index.vue'
      ),
    beforeEnter: authGuard
  },
  {
    path: '*',
    name: 'notFound',
    beforeEnter: authGuard,
    component: () =>
      import(/* webpackChunkName: "onboardingDashboard" */ '@/pages/OnBoarding/NotFound.vue')
  }
]

export default routes
